<template>
  <a-modal :class="$style.container" v-model="isShow" :title="('地址信息 - ' + this.deptName)" :width="850" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :rules="resRules" :class="$style.form__ct" class="al-form"
                  :label-col="{ style: 'width: 150px' }" :wrapper-col="{ style: 'width: 550px' }" layout="inline"
                  :model="form">
        <a-row  type="flex" justify="space-between">
          <a-form-model-item label="所在地区" prop="provinceCode">
            <span>
              <a-select v-model="form.provinceCode" :placeholder="'请选择省'"
                        style="width: 120px" @change="handleProvinceChange">
                <a-select-option v-for="(items, index) in provinceList" :key="index"
                                 :value="items.areaCode">{{ items.name }}</a-select-option>
              </a-select>
            </span>
            <span>
              <a-select v-model="form.cityCode" :placeholder="'请选择市'" @change="handleProvinceChange2"
                        style="width: 120px;margin-left:20px">
                <a-select-option v-for="(items, index) in cityList" :key="index"
                                 :value="items.areaCode">{{ items.name }}</a-select-option>
              </a-select>
            </span>
            <span>
              <a-select v-model="form.districtCode" :placeholder="'请选择区'" @change="handleProvinceChange3"
                      style="width: 120px;margin-left:20px">
              <a-select-option v-for="(items, index) in areaList" :key="index"
                               :value="items.areaCode">{{ items.name }}</a-select-option>
              </a-select>
            </span>
          </a-form-model-item>
        </a-row>
        <a-form-model-item label="详细地址:" prop="address">
          <a-input v-model="form.address" placeholder="请输入详细地址" style="width: 400px;" />
        </a-form-model-item>
        <a-form-model-item label="经度:" prop="longitude">
          <a-input-number v-model="form.longitude" placeholder="请输入经度" style="width: 400px;" />
        </a-form-model-item>
        <a-form-model-item label="纬度:" prop="latitude">
          <a-input-number v-model="form.latitude" placeholder="请输入纬度" style="width: 400px;"/>
        </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    const validProvinceCode = (rule, value, callback) => {
      if (value) {
        if (!value || !this.form.cityCode || !this.form.districtCode) {
          return callback(new Error('省市区不能为空'))
        }
      }
      callback()
    }
    return {
      provinceList: [], // 省列表
      cityList: [], // 省列表
      areaList: [], // 区列表
      loading1: false,
      disabledStatus: true,
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      deptId: '',
      deptName: '',
      form: {
        id: '',
        countryCode: 'CHN',
        countryName: '中国',
        provinceName: '',
        provinceCode: '',
        cityName: '',
        cityCode: '',
        districtName: '',
        districtCode: '',
        address: '',
        longitude: '',
        latitude: ''
      },
      resRules: {
        provinceCode: [{
          required: true,
          message: '省市区不能为空',
          trigger: 'change'
        },
        { validator: validProvinceCode, trigger: 'change' }
        ],
        address: [{
          required: true,
          message: '详细地址不能为空',
          trigger: 'change'
        }],
        longitude: [{
          required: true,
          message: '经度不能为空',
          trigger: 'change'
        }],
        latitude: [{
          required: true,
          message: '纬度不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  created () {
    this.getChildAdres()
  },
  computed: {
  },
  methods: {
    async getDeptAddress (deptId) {
      const res = await this.$store.dispatch('http', {
        api: 'getDeptAddress',
        query: {
          id: deptId
        }
      })
      if (res) {
        this.loadProv(res)
        this.form = res
      }
    },
    changeSelect () {
    },
    // 设置表单
    setForm (form = {}) {
      this.deptId = form.userId
      this.deptName = form.realName
      this.getDeptAddress(this.deptId)
    },
    close () {
      this.$refs.form.resetFields()
      this.form = {
      }
    },
    // 编辑时加载市区
    async loadProv (item) {
      console.log(item)
      Object.assign(this.form, item)
      if (this.form.provinceCode) {
        const { id } = this.provinceList.filter(item => item.areaCode === this.form.provinceCode)[0]
        await this.getChildAdres(id, 'cityList')
        const ele = this.cityList.filter(item => item.areaCode === this.form.cityCode)[0]
        await this.getChildAdres(ele.id, 'areaList')
      }
    },
    async handleProvinceChange (e) {
      this.cityList = []
      this.form.cityCode = ''
      this.form.cityName = ''
      this.form.districtCode = ''
      this.form.districtName = ''
      const { id, name } = this.provinceList.filter(item => item.areaCode === e)[0]
      this.form.provinceName = name
      await this.getChildAdres(id, 'cityList')
    },
    async handleProvinceChange2 (e) {
      this.areaList = []
      this.form.districtCode = ''
      const { id, name } = this.cityList.filter(item => item.areaCode === e)[0]
      this.form.cityName = name
      await this.getChildAdres(id, 'areaList')
    },
    handleProvinceChange3 (e) {
      const { name } = this.areaList.filter(item => item.areaCode === e)[0]
      this.form.districtName = name
      this.$forceUpdate()
    },
    // 按上级查询下级地区列表
    async getChildAdres (id = 2, type = 'provinceList') {
      const result = await this.$store.dispatch('http', {
        api: 'ChildAdres',
        query: { id: id }
      })
      this[type] = result
    },
    // 提交保存
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          params.countryCode = 'CHN'
          params.countryName = '中国'
          let api = 'editDeptAddress'
          await this.$store.dispatch('http', {
            api,
            query: {
              id: this.deptId
            },
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '新增') + '成功~' })
          this.isShow = false
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
